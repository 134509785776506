<template>
  <div class="sld_finance_list">
    <!-- <MemberTitle :memberTitle="L['发票管理']"></MemberTitle> -->
    <div class="container">
      <h3>{{ L["发票管理"] }}</h3>

      <div class="outer5 flex-col">
        <div class="section5 flex-row">
          <div class="mod9 flex-col">
            <div class="outer6 flex-row">
              <span class="word25">可开票金额</span>
              <img class="label8" src="@/assets/tips.png" />
              <div class="outer6_tip">
                <div>可开票金额=总计消费可开票金额-历史已开票金额</div>
              </div>
            </div>
            <span class="word26">¥{{ invoicedAmount.invoicedAmount }}</span>
            <span class="info9" v-if="invoicedAmount.totalAmount && invoicedAmount.historyAmount">
              = ¥{{ invoicedAmount.totalAmount }} - ¥{{ invoicedAmount.historyAmount }}
            </span>
            <span class="info9" v-else> </span>
            <div class="outer7 flex-col" @click="addInvoice">
              <span class="info10">去开票</span>
            </div>
          </div>
          <div class="mod10 flex-col"></div>
          <div class="mod11 flex-col">
            <span class="word27">发票抬头</span>
            <span class="word28">{{ autoInfo.header.invoiceTitle ? autoInfo.header.invoiceTitle : '暂无发票' }}</span>
            <div class="section6 flex-col" @click="setInvoiceHeader">
              <span class="word29">管理发票抬头</span>
            </div>
          </div>
          <div class="mod12 flex-col"></div>
          <div class="mod13 flex-col">
            <span class="word30">寄送信息</span>
            <span class="word31" v-if="autoInfo.address.memberName">
              <span :title="autoInfo.address.memberName">{{ autoInfo.address.memberName }}</span>
              <span :title="autoInfo.address.telMobile">{{ autoInfo.address.telMobile }}</span>
            </span>
            <span class="word31" v-else>暂无信息</span>
            <span class="word31" v-if="autoInfo.address.addressAll"
              :title="autoInfo.address.addressAll + ' ' + autoInfo.address.detailAddress">
              {{ autoInfo.address.addressAll + ' ' + autoInfo.address.detailAddress }}
            </span>
            <span class="word31" v-if="autoInfo.email.email" :title="autoInfo.email.email">{{
              '电子邮箱:' +
                autoInfo.email.email
            }}</span>
            <div class="mod14 flex-col" @click="setInvoiceAddress">
              <span class="word32">管理寄送信息</span>
            </div>
          </div>
        </div>
      </div>

      <span class="info11">开票记录</span>
      <div class="outer8 flex-row">
        <span class="word33">发票号：</span>
        <div class="bd1 flex-col">
          <el-input v-model="searchVal.invoiceCode" placeholder="请输入发票号" />
        </div>
        <span class="word34">发票状态：</span>
        <div class="bd2 flex-col">
          <el-select v-model="searchVal.state" placeholder="请选择">
            <el-option v-for="item in invoiceState" :key="item" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <span class="word34">采购单号：</span>
        <div class="bd1 flex-col">
          <el-input v-model="searchVal.purchaseSn" placeholder="请输入采购单号" />
        </div>
      </div>

      <div class="outer9 flex-row">
        <span class="word37">订单号：</span>
        <div class="bd1 flex-col">
          <el-input v-model="searchVal.orderSn" placeholder="请输入订单编号" />
        </div>
        <span class="word34">申请时间：</span>
        <div class="bd3 flex-col">
          <el-date-picker v-model="selectTime" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" value-format="YYYY-MM-DD">
          </el-date-picker>
        </div>
        <button class="group11 flex-col" @click="goSelect">
          <span class="word43">搜索</span>
        </button>
        <div class="group12 flex-col" @click="initSearch">
          <span class="txt19">重置</span>
        </div>
      </div>

      <template v-if="invoiceList.data.length == 0">
        <div class="outer11 flex-col">
          <div class="main1 flex-row">
            <span class="info15">申请时间</span>
            <span class="info15 txt20">发票号</span>
            <span class="info15 info16">发票类型</span>
            <span class="info15 word44">发票抬头</span>
            <span class="info15 info17">开票主体</span>
            <span class="info15 txt21">订单号</span>
          </div>
        </div>
        <div class="outer12 flex-col">
          <div class="main2 flex-col">
            <div class="outer13 flex-col"></div>
            <span class="word45">暂无开票记录</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="mod_list">
          <div class="mod6 flex-col">
            <div class="outer10 flex-row">
              <span class="word42">申请时间</span>
              <span class="word42 txt21">发票号</span>
              <span class="word42 word43">发票类型</span>
              <span class="word42 word44">发票抬头</span>
              <span class="word42 word45">开票主体</span>
              <span class="word42 word47">订单号</span>
              <span class="word42 word48">采购单号</span>
              <span class="word42 info20">发票金额</span>
              <span class="word42 txt22">发票备注</span>
              <span class="word42 word49">发票性质</span>
              <span class="word42 word50">发票状态</span>
              <span class="word42 txt23">操作</span>
            </div>
          </div>
          <div class="mod7 flex-col">
            <template v-for="(item, index) in invoiceList.data" :key="item">
              <div class="wrap6 flex-row">
                <span class="txt25">{{ item.createTime }}</span>
                <span class="txt25 txt26">{{ item.invoiceCode ? item.invoiceCode : '--' }}</span>
                <span class="txt25 word51">{{ item.invoiceTypeValue }}</span>
                <span class="txt25 word52">{{ item.invoiceTitle }}</span>
                <span class="txt25 txt27">{{ item.storeName }}</span>


                <div class="section16 flex-col">
                  <span v-for="items in item.invoiceOrderList" :key="items.orderSn" class="txt28"
                    :class="{ isMore: item.isOrderMore }">
                    <router-link :to="`/member/order/info?orderSn=${items.orderSn}`">
                      {{ items.orderSn }}</router-link>
                  </span>


                  <span class="txt281" @click="orderSnMore(item, 'isOrderMore')"
                    v-show="item.invoiceOrderList.length > 3">{{
                      item.isOrderMore ? '收起' : '更多'
                    }} <i :class="`el-icon-arrow-${item.isOrderMore ? 'up' : 'down'}`"></i></span>


                  <span class="txt28" v-show="!item.invoiceOrderList.length">--</span>
                </div>




                <div class="section17 flex-col">
                  <span v-for="items in item.invoiceOrderList" :key="items.orderSn" class="info25"
                    :class="{ isMore: item.isPurMore }">
                    {{ items.purchaseSn ? items.purchaseSn : '--' }}
                  </span>

                  <span class="txt281" @click="orderSnMore(item, 'isPurMore')"
                    v-show="item.invoiceOrderList.length > 3">{{
                      item.isPurMore ? '收起' : '更多'
                    }} <i :class="`el-icon-arrow-${item.isPurMore ? 'up' : 'down'}`"></i></span>
                </div>







                <span class="txt25 word56">¥{{ item.invoiceAmount }}</span>

                <template v-if="item.memberRemark">
                  <button class="section18 flex-col" @click="editRemark(index)">
                    <span class="word57">{{ item.memberRemark }}</span>
                  </button>
                  <!-- <img class="icon7" src="@/assets/prototype/sketch_complete.png"/> -->
                </template>
                <template v-else>
                  <i class="iconfont iconbianji icon8" @click="editRemark(index)"></i>
                </template>

                <template v-if="item.type == '1'">
                  <span class="info26" style="margin-right:22px;text-align:center;">电子</span>
                  <template v-if="item.invoiceUrl">
                    <template v-for="(items, indexs) in item.invoiceUrl.split(',')" :key="indexs">
                      <i v-if="items.state == 2" @click="downloadInvoice(items)"
                        class="iconfont iconshangchuan2 label10"></i>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <span class="info26" style="width:44px;text-align:center;margin-right:22px;">纸质</span>
                </template>

                <span class="txt25 info27">{{ item.stateValue }}</span>
                <div class="txt25">
                  <span class="word58" @click="detail(item)">查看详情</span>

                  <template v-if="item.state == 2">
                    <br v-if="item.type == 2" />

                    <span class="word58" @click="lookLogistics(item)" v-if="item.type == 2">查看物流</span>

                    <span class="word58" @click="cancelInvoice(item)" v-if="item.type == 1">作废</span>
                  </template>


                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
      <el-pagination @current-change="handleCurrentChange" :currentPage="pageData.current"
        :page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
        :hide-on-single-page="false" class="flex_row_end_center" v-if="invoiceList.data.length > 0"></el-pagination>
    </div>

    <!-- 编辑发票备注 start -->
    <el-dialog ref="editApplynum" title="编辑发票备注" v-model="editDialog" width="480px" top="30vh">
      <div class="edit_main">
        <span>发票备注：</span>
        <el-input type="textarea" autosize resize="none" v-model="remarkVal" maxlength="100" placeholder="请输入发票备注"
          show-word-limit />
      </div>
      <div class="edit_btn">
        <div class="edit_cancle" @click="close_edit">取消</div>
        <div class="edit_submit" @click="submit_edit">确定</div>
      </div>
    </el-dialog>
    <!-- 编辑发票备注 end -->



    <!-- 查看物流弹窗 start -->
    <el-dialog :title="L['物流信息']" v-model="show_logistics" customClass="select_reason_width" :before-close="handleClose"
      lock-sroll="false">
      <div class="logistics_info">
        <p>{{ logistics_info.data.type == 1 ? L['联系电话'] : L['承运人'] }}：{{ logistics_info.data.expressName }}</p>
        <p>{{ logistics_info.data.type == 1 ? L['联系人'] : L['运单号'] }}：{{ logistics_info.data.expressNumber }}</p>
      </div>
      <div class="logistics_list_con">
        <el-timeline>
          <el-timeline-item v-for="(logistics, index) in logistics_info.data.routeList" :key="index"
            :timestamp="logistics.acceptStation ? logistics.acceptStation : logistics.remark">{{
  logistics.acceptTime
            }}</el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
    <!--  查看物流弹窗 end  -->






  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import MemberTitle from "@/components/MemberTitle";
import { useRouter } from "vue-router";
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
export default {
  name: "FinaceInvoice",
  components: {
    MemberTitle,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const router = useRouter();
    const store = useStore();
    const invoiceList = reactive({ data: [] });
    const searchVal = reactive({});
    const selectTime = ref('');
    const show_logistics = ref(false)
    const logistics_info = reactive({ data: [] })
    const pageData = reactive({
      current: 1,
      pageSize: 10,
      total: 0
    });
    const invoiceState = ref([
      { id: 0, name: '全部', value: '' },
      { id: 1, name: '开票中', value: '1' },
      { id: 2, name: '已开票', value: '2' },
      { id: 5, name: '已作废', value: '5' }

    ]);
    const invoiceStore = ref([]);
    const autoInfo = reactive({ header: {}, address: {}, email: {} });
    /** 编辑发票备注 */
    const editDialog = ref(false);
    const remarkVal = ref('');
    const editIndex = ref('');
    const invoicedAmount = reactive({
      invoicedAmount: 0,
      totalAmount: 0,
      historyAmount: 0
    }); //可索取发票金额

    const goSelect = () => {
      searchVal.startTime = selectTime.value ? selectTime.value[0] : '';
      searchVal.endTime = selectTime.value ? selectTime.value[1] : '';
      getInvoiceList();
    };

    const initSearch = () => {
      searchVal.invoiceCode = '';
      searchVal.state = '';
      searchVal.startTime = '';
      searchVal.endTime = '';
      searchVal.storeId = '';
      searchVal.goodsName = '';
      searchVal.orderCode = '';
      searchVal.productCode = '';
      searchVal.orderSn = '';
      searchVal.purchaseSn = '';
      selectTime.value = '';
      getInvoiceList();
    };

    const orderSnMore = (item, type) => {
      item[type] = !item[type]
    }

    const getStoreList = () => { //获取发票主体数据
      proxy.$get('v3/seller/front/store/storeList').then(res => {
        invoiceStore.value = res.data ? res.data : [];
      })
    };

    const getAmount = () => { //获取可索取发票金额
      proxy.$get('v3/business/front/invoice/invoicedAmount').then(res => {
        invoicedAmount.invoicedAmount = res.data.invoicedAmount ? res.data.invoicedAmount : 0;
        invoicedAmount.totalAmount = res.data.totalAmount ? res.data.totalAmount : 0;
        invoicedAmount.historyAmount = res.data.historyAmount ? res.data.historyAmount : 0;
      })
    };

    const getInvoiceList = () => { //获取开票列表
      let param = {
        current: pageData.current,
        pageSize: pageData.pageSize
      };
      if (searchVal.invoiceCode) {
        param.invoiceCode = searchVal.invoiceCode //发票编码
      }
      if (searchVal.state) {
        param.state = searchVal.state //发票状态，1-开票中，2-已开票,默认全部
      }
      if (searchVal.startTime) {
        param.startTime = searchVal.startTime + ' 00:00:00'//申请开始时间
        param.endTime = searchVal.endTime + ' 23:59:59'//申请结束时间
      }
      if (searchVal.storeId) {
        param.storeId = searchVal.storeId //发票主体
      }
      if (searchVal.goodsName) {
        param.goodsName = searchVal.goodsName //产品名称
      }
      if (searchVal.orderCode) {
        param.orderCode = searchVal.orderCode //规格型号
      }
      if (searchVal.productCode) {
        param.productCode = searchVal.productCode //物料编码
      }
      if (searchVal.orderSn) {
        param.orderSn = searchVal.orderSn //订单号
      }
      if (searchVal.purchaseSn) {
        param.purchaseSn = searchVal.purchaseSn //采购单号
      }
      proxy
        .$get("v3/business/front/invoice/list", param)
        .then((res) => {
          if (res.state == 200) {
            invoiceList.data = res.data.list;
            pageData.total = res.data.pagination.total;
          } else {
            ElMessage.warning(res.msg);
          }
        });
    };

    const getAutoInfo = () => { //获取顶部展示信息
      /** 发票抬头 */
      proxy
        .$get('v3/member/front/invoiceTitle/list')
        .then(res => {
          if (res.data.list.length > 0) {
            let header = null;
            res.data.list.map(item => {
              if (item.isDefault == 1) {
                header = item;
              }
            })
            if (!header) {
              header = res.data.list[0];
            }
            autoInfo.header = header;
          }
        })

      /** 寄送地址 */
      proxy
        .$get("v3/member/front/memberInvoiceAddress/list")
        .then((res) => {
          if (res.data.list.length > 0) {
            let address = null;
            res.data.list.map(item => {
              if (item.isDefault == 1) {
                address = item;
              }
            })
            if (!address) {
              address = res.data.list[0];
            }
            autoInfo.address = address;
          }
        });

      /** 寄送地址 */
      proxy
        .$get("v3/member/front/memberInvoiceEmail/list")
        .then((res) => {
          if (res.data.list.length > 0) {
            let email = null;
            res.data.list.map(item => {
              if (item.isDefault == 1) {
                email = item;
              }
            })
            if (!email) {
              email = res.data.list[0];
            }
            autoInfo.email = email;
          }
        });
    };

    const addInvoice = () => { //去开票
      router.push(`/member/invoice/add`);
    }

    const setInvoiceHeader = () => { //管理发票抬头
      router.push(`/member/invoice/header`);
    };

    const setInvoiceAddress = () => { //管理寄送信息
      router.push(`/member/invoice/address`);
    };

    const editRemark = (index) => { //编辑发票备注
      editDialog.value = true;
      remarkVal.value = invoiceList.data[index].memberRemark ?
        invoiceList.data[index].memberRemark : ''
      editIndex.value = index;
    };
    const close_edit = () => {
      editDialog.value = false;
      remarkVal.value = '';
      editIndex.value = '';
    };
    const submit_edit = () => {
      if (!remarkVal.value) {
        ElMessage.warning('发票备注不能为空')
        return;
      }
      let param = {
        invoiceId: invoiceList.data[editIndex.value].invoiceId,
        memberRemark: remarkVal.value
      }
      proxy
        .$post('v3/business/front/invoice/update', param)
        .then(res => {
          if (res.state == 200) {
            invoiceList.data[editIndex.value].memberRemark = remarkVal.value;
            ElMessage.success('编辑成功');
            close_edit();
          } else {
            ElMessage.error('编辑失败');
          }
        })
    };

    const detail = (item) => { //查看发票详情
      let invoiceProductIds = [];
      item.productInfoList.map(items => {
        invoiceProductIds.push(items.invoiceProductId)
      })
      router.push({
        path: `/member/invoice/info`,
        query: {
          addressId: item.addressInfo ? JSON.parse(proxy.$quillEscapeToHtml(item.addressInfo)).addressId : '',
          invoiceId: item.invoiceId,
          invoiceProductIds: invoiceProductIds.join(',')
        }
      });
    };
    //页数改变
    const handleCurrentChange = e => {
      pageData.current = Math.floor(e);
      getInvoiceList();
    };

    //下载电子发票
    const downloadInvoice = (href) => {
      if (href) {
        window.open(href, '_blank');
      }
    };


    const cancelInvoice = item => {
      proxy.$confirm('确定作废？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        proxy.$get('v3/business/front/invoice/invoiceCancel', {
          invoiceId: item.invoiceId
        }).then(res => {
          if (res.state == 200) {
            getInvoiceList()
          }
        })
      })
    }


    //显示查看物流信息
    const lookLogistics = (item) => {
      proxy
        .$get("v3/business/front/logistics/invoice/getTrace", {
          invoiceId: item.invoiceId
        })
        .then(res => {
          if (res.state == 200) {
            show_logistics.value = true;
            logistics_info.data = res.data;
          } else {
            ElMessage(res.msg);
          }
        })
    };

    onMounted(() => {
      getAmount();
      getAutoInfo();
      initSearch();
      getStoreList();
    })

    return {
      L,
      show_logistics,
      logistics_info,
      lookLogistics,
      orderSnMore,
      pageData,
      autoInfo,
      searchVal,
      invoicedAmount,
      getAmount,
      getInvoiceList,
      getAutoInfo,
      goSelect,
      initSearch,
      selectTime,
      invoiceState,
      invoiceStore,
      invoiceList,
      addInvoice,
      setInvoiceHeader,
      setInvoiceAddress,
      detail,
      editRemark,
      editDialog,
      remarkVal,
      editIndex,
      close_edit,
      submit_edit,
      handleCurrentChange,
      getStoreList,
      downloadInvoice,
      cancelInvoice
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/finace/invoice.scss";
</style>
<style lang="scss">
.sld_finance_list {
  .bd1 .el-input__inner {
    width: 222px;
    height: 32px;
    position: absolute;
    left: 0;
    top: 0;
  }

  .bd2 .el-select {
    position: absolute;
    left: 0;
    top: 0;
  }

  .bd2 .el-input--suffix .el-input__inner {
    width: 222px;
    height: 32px;
  }

  .bd2 .el-select .el-input {
    width: 222px;
  }

  .bd2 .el-input__suffix {
    top: -3px;
  }

  .bd3 .el-range-editor.el-input__inner {
    width: 222px;
    height: 32px;
  }

  .bd3 .el-date-editor .el-range-separator {
    width: 26px;
    line-height: 24px;
  }

  .bd3 .el-range-editor .el-range-input {
    font-size: 12px;
  }

  .el-pagination {
    padding-top: 15px;
  }

  .edit_main .el-textarea .el-input__count {
    bottom: -20px;
  }
}

.is-right {
  .el-date-range-picker__header {
    .el-icon-arrow-right {
      margin: 8px 0 0;
    }
  }
}
</style>